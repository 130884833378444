import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../layouts/Layout'
import Disclaimer from '../components/Disclaimer'

import { disclaimerPage } from './disclaimer.module.scss'

const disclaimer = () => {
  return (
    <Layout doNotShowDisclaimer hideLogoText={false} showNavigation={false} footerVisible={false} className={disclaimerPage}>
      <Helmet>
        <title>Disclaimer - Emso</title>
      </Helmet>
      <Disclaimer />
    </Layout>
  )
}

export default disclaimer
